import { defineStore } from 'pinia';

interface Flavor {
  price?: number;
}

interface Complement {
  price?: number;
}

interface Size {
  price?: number;
}

interface Product {
  flavor?: Flavor[];
  complement?: Complement[];
  size?: Size[];
}

interface OrderItem {
  price?: number;
  quantity: number;
  product: Product;
}

interface Order {
  items: OrderItem[];
}

export const useOrderStore = defineStore('orderStore', {
  state: () => ({
    orders: [] as Order[], // Definindo o tipo correto
  }),
  actions: {
    setOrders(orders: Order[]) {
      this.orders = orders || [];
    },
    addOrder(order: Order) {
      this.orders.push(order);
    },
    calculateItemTotal(item: OrderItem): number {
      const basePrice = item.price || 0;

      // Calcula o total dos flavors
      const flavorPrice = item.product.flavor?.reduce((acc: number, flavor: Flavor) => acc + (flavor.price || 0), 0) || 0;

      // Calcula o total dos complements
      const complementPrice = item.product.complement?.reduce((acc: number, complement: Complement) => acc + (complement.price || 0), 0) || 0;

      // Calcula o preço do tamanho (size)
      const sizePrice = item.product.size?.reduce((acc: number, size: Size) => acc + (size.price || 0), 0) || 0;

      // Preço final do item, incluindo quantidade
      return (basePrice + flavorPrice + complementPrice + sizePrice) * item.quantity;
    },
  },
});
